<template>
  <section>
    <b-card no-body>
      <b-card-header>
        <b-card-title class="d-flex w-100">
          <div class="flex-grow-1">
            <slot name="title" />
          </div>
          <div class="text-right">
            <slot name="button" />
            <b-button variant="secondary" @click="loadList()" class="mr-1 btn-icon">
              <feather-icon icon="RefreshCwIcon" />
            </b-button>
            <b-button variant="secondary" class="mr-1 btn-icon" @click="resetFilter()">
              <feather-icon icon="DeleteIcon" />
            </b-button>
            <b-button variant="secondary" class="btn-icon" id="column_setting" v-if="hideable_column.length > 0">
              <feather-icon icon="PauseIcon" />
            </b-button>
            <b-popover target="column_setting" triggers="focus" placement="leftbottom" :delay="0">
              <div style="overflow-y: auto" :style="{ 'max-height': height + 'px' }" class="m-0 p-0">
                <table class="table table-hover table-sm">
                  <tbody>
                    <tr v-for="(v, i) in hideable_column" :key="i">
                      <td style="padding: 5px; border: none">
                        <b-form-checkbox v-model="show_column" :value="v.key">{{ v.label }}</b-form-checkbox>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <b-button block variant="light" @click="resetColumnFilter">Reset</b-button>
              </div>
            </b-popover>
          </div>
        </b-card-title>
      </b-card-header>
      <b-card-body>
        <div :style="{ height: (height == null ? 'auto':(height + 'px')) }">
          <b-table v-if="rows != null" hover responsive :sticky-header="height - 5 + 'px'" :items="rows" :fields="columns_after_hidden_check" primary-key="id" show-empty empty-text="No matching records found" :busy="loading" @row-clicked="rowClicked" no-local-sorting :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
            <template #head()="data">
              <div class="mt-1 w-100 text-center text-nowrap" style="padding-bottom: 2px">
                {{ data.label }}
                <template v-if="data.field.sortable">
                  <feather-icon icon="ChevronUpIcon" :class="{ 'text-primary': !sortDesc && sortBy === data.field.key, 'text-secondar': sortDesc || sortBy !== data.field.key }" />
                  <feather-icon icon="ChevronDownIcon" :class="{ 'text-primary': sortDesc && sortBy === data.field.key, 'text-secondar': !sortDesc || sortBy !== data.field.key }" />
                </template>
              </div>
              <b-form-input v-model.trim="filter[data.field.modelName]" @keydown.enter.native="search()" autocomplete="off" v-if="data.field.filtertype === 'input'" />
              <flat-pickr class="form-control bg-white" v-model="filter[data.field.key]" @on-change="search()" :config="{ enableTime: false }" v-else-if="data.field.filtertype === 'date'" />
              <flat-pickr class="form-control bg-white" v-model="filter[data.field.key]" @on-change="search()" :config="{ mode: 'range' }" v-else-if="data.field.filtertype === 'datetime'" />
              <b-form-select :options="data.field.options" v-model="filter[data.field.key]" @change="search()" v-else-if="data.field.filtertype === 'select'" />
            </template>

            <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"><slot :name="slot" v-bind="scope" /></template>
          </b-table>
        </div>
        <!-- show pages -->
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mr-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="size"
              :options="pageOptions"
              class="mx-1"
              @change="handlePageChange"
            />
            <span class="text-nowrap"> of {{ totalSize }} entries </span>
          </div>
          <div>
            <b-pagination
              v-model="page"
              :total-rows="totalSize"
              :per-page="size"
              first-number
              last-number
              class="mb-0 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
import {
  BPagination, BFormSelect, BButton, BTooltip, VBToggle,
  BCard, BCardBody, BTable, BRow, BCol, BFormInput, BFormGroup, BSidebar,
  BInputGroupPrepend,BCardHeader, BCardTitle,
  BInputGroup,BPopover,
  BBreadcrumb,
  BBreadcrumbItem, BBadge, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import "@core/scss/vue/libs/vue-flatpicker.scss";

export default {
  components: {
    ToastificationContent,
    // ContentLoader,
    flatPickr,BPopover,
    BPagination,
    BFormSelect,
    BButton,
    BTooltip,
    BCard,
    BCardBody,
    BTable,
    BRow,
    BCol,
    flatPickr,
    BFormGroup,
    BCardHeader, BCardTitle,
    BSidebar,
    vSelect,
    BInputGroupPrepend,
    BInputGroup,
    BBreadcrumb,
    BBreadcrumbItem,
    BBadge,
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BSpinner,
  },
  props: {
    url: {
      type: String
    },
    columns: {
      default: () => [],
      type: Array
    },
    height: {
      default: 355,
      type: Number
    },
    defaultPageSize: {
      default: 5,
      type: Number
    },
    id:{
      type:Number
    },
    refreshTable: {
      default: false,
      type: Boolean
    },
    onRowClicked: {
      default: null,
      type: Function
    }
  },
  computed: {
    columns_after_hidden_check() {
      return this.columns
        .filter((e) => !e.adminOnly || this.is_admin && e.adminOnly)
        .filter((e) => e.hideable == null || e.hideable == false || this.show_column.includes(e.key))
        .map((e) => {
          let ret = JSON.parse(JSON.stringify(e));
          ret["label"] = this.$t(e.label);
          ret["thStyle"] = { padding: "0px 3px 5px 3px" };
          if (e.width) {
            ret["thStyle"]["width"] = e.width + "px";
            ret["thStyle"]["max-width"] = e.width + "px";
            ret["thStyle"]["min-width"] = e.width + "px";
          }
          if (e.minWidth) {
            ret["thStyle"]["min-width"] = e.minWidth + "px";
          }
          if (e.sortable) ret["thStyle"]["backgroundImage"] = "none !important";
          ret["thClass"] = ["bg-white"];
          ret["tdClass"] = ["text-nowrap"];
          ret["tdClass"] = ["text-" + (e.align ? e.align : "left"), "p-1"];
          ret["options"] = e.options ? [{ value: "", text: "" }].concat(Object.keys(e.options).map((k) => ({ value: k, text: e.options[k] }))) : [{ value: "", text: "" }];
          return ret;
        });
    },
    hideable_column() {
      return this.columns.filter((e) => !e.adminOnly || this.is_admin && e.adminOnly).filter((e) => e.hideable);
    },
    empty_filter() {
      let hash = {};
      this.columns.forEach((e) => {
        hash[e.key] = "";
      });
      return hash;
    }
  },
  data() {
    return {
      loading: false,
      rows: null,
      pageOptions: [50, 100, 200],
      page: null, // 当前页码
      size: 15, // 每页个数
      totalSize: null, // 总个数
      totalPage: null, // 总页数
      condition: {
        page: '1',
        size: '50',
      },
      filter: {},
      current_filter: {},
      sortBy: "",
      sortDesc: true,
      show_column: [],
      is_admin: false,
    };
  },
  mounted() {
    this.is_admin = localStorage.getItem("isAdmin") === "Y";
    this.filter = JSON.parse(JSON.stringify(this.empty_filter));
    this.current_filter = JSON.parse(JSON.stringify(this.empty_filter));
    if (localStorage.getItem("show_column_" + this.url) == null) {
      this.resetColumnFilter();
    } else {
      this.show_column = JSON.parse(localStorage.getItem("show_column_" + this.url));
    }
    this.loadList();
  },
  methods: {
     handleChangePage(page) {
      this.condition.page = page
      this.loadList()
    },
    handlePageChange(active) {
      this.condition.page = 1
      this.condition.size = active
      this.loadList()
    },
    loadList(item) {
      this.loading = true;
      let url = this.url;
      // url += "&pageNumber=" + (this.page.page_number - 1);
      // url += "&pageSize=" + this.page.page_size;
      // url += "&sortDirection=" + (this.sortDesc ? "desc" : "asc");
      const sortByField = this.columns.filter((e) => e.key === this.sortBy);
      let label;
      let value;

      // this.columns
      //   // .filter((e) => !this.hideColumns.includes(e.key))
        // .forEach((e) => {
        //   if (e.filtertype === "input") {
        //     this.filter[e.key] = this.filter[e.key].trim();
            // if (this.filter[e.key] != "") url += "&" + e.modelName + "=" + this.filter[e.key];
          // } else if (e.filtertype === "select") {
            // if (this.filter[e.key] != "") url += "&" + e.modelName + "=" + this.filter[e.key];
          // } else if (e.filtertype === "datetime") {
          //   if (this.filter[e.key] != "") {
          //     const d = this.filter[e.key].split(" to ");
          //     if (d.length == 2) {
          //       this.filter[e.modelName]+'From' == d[0] + "T00:00:00.000%2B08:00"
          //       this.filter[e.modelName]+'To' ==  d[1] + "T23:59:59.000%2B08:00"
          //     }else{
          //       this.filter[e.modelName]+'From' == d[0] + "T00:00:00.000%2B08:00"
          //       this.filter[e.modelName]+'To' ==  d[0] + "T23:59:59.000%2B08:00"
          //     }
              // if (d.length == 2) url += "&" + e.modelName + "From=" + d[0] + "T00:00:00.000%2B08:00&" + e.modelName + "To=" + d[1] + "T23:59:59.000%2B08:00";
              // else url += "&" + e.modelName + "From=" + d[0] + "T00:00:00.000%2B08:00&" + e.modelName + "To=" + d[0] + "T23:59:59.000%2B08:00";
        //     }
        //   }
        // });
      this.current_filter = JSON.parse(JSON.stringify(this.filter));
      if(this.id){
        this.current_filter.organizationId = this.id
      }
      this.current_filter.page = this.condition.page
      this.current_filter.size = this.condition.size
      this.current_filter.orderMole = this.sortDesc ? "0" : "1"
      if (sortByField.length === 1) {
        this.current_filter.orderBy = sortByField[0].modelName
      }
      if (item) {
        for (let a in item) {
          label = a
          value = item[a]
          if (this.current_filter.hasOwnProperty(label)) {
            for(let b in this.current_filter){
              if (b == label) {
                this.current_filter[b] = value
              }
            }
          }else{
            this.$set(this.current_filter,label,value)
          }
        }
      }
      this.$emit("download", this.current_filter);
      this.$http.post(url,this.current_filter).then((res) => {
        const info = res.data.data;
        this.rows = info.content
        this.page = info.page
        this.size = info.size
        this.totalSize = info.totalSize
        this.totalPage = info.totalPage
        this.loading = false;
      });
    },
    search() {
      if (JSON.stringify(this.filter) !== JSON.stringify(this.current_filter)) {
        this.loadList();
      }
    },
    resetFilter() {
      this.filter = JSON.parse(JSON.stringify(this.empty_filter));
      this.$emit("clear");
      this.search();
    },
    openModalForEdit(item) {
      this.$emit("edit", item);
    },
    resetColumnFilter() {
      this.show_column = this.columns.filter((e) => !e.defaultHide).map((e) => e.key);
    },
    rowClicked(val) {
      if (this.onRowClicked) {
        this.onRowClicked(val);
      } else {
        this.openModalForEdit(val);
      }
    }
  },
  watch: {
    sortBy(n, o) {
      this.loadList();
    },
    sortDesc(n, o) {
      this.loadList();
    },
    show_column(n, o) {
      localStorage.setItem("show_column_" + this.url, JSON.stringify(n));
    },
    refreshTable() {
      this.loadList();
    }
  }
};
</script>

<style scoped></style>
