<template>
<div>
    <section>
        <AdvanceTable ref="advanceTable" url="sfService/searchByPage" :height="window.height - 280" :columns="columns" :pageOptions="[50, 100, 200]" :defaultPageSize="50" @clear="clearData" >
        <template #title>
            {{ $t("SF One-Stop Monitor") }}
        </template>
        <template #cell(inboundStatus)="row">
          <b-badge variant="success"  v-if="row.item.inboundStatus == true">Yes</b-badge>
          <b-badge variant="danger"  v-if="row.item.inboundStatus == false">No</b-badge>
        </template>
        <template #cell(status)="row">
          <b-badge variant="success"  v-if="row.item.status == 'success'">success</b-badge>
          <b-badge variant="danger"  v-if="row.item.status == 'fail'">fail</b-badge>
          <b-badge variant="warning"  v-if="row.item.status == 'pending'">pending</b-badge>
        </template>
        <template #cell(action)="row">
        <b-dropdown size="sm" variant="link" no-caret>
          <template #button-content>
            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body"></feather-icon>
          </template>
          <b-dropdown-item href="#" @click="resendToSF(row.item)">{{ $t("Resend to SF") }}</b-dropdown-item>
        </b-dropdown>
      </template>
        <template #head(createdAt)="data">
            <div class="mt-1 w-100 text-center text-nowrap" style="padding-bottom: 2px;">
                {{ data.label }}
                <template v-if="data.field.sortable">
                  <feather-icon icon="ChevronUpIcon" :class="{ 'text-primary': !sortDesc && sortBy === data.field.key, 'text-secondar': sortDesc || sortBy !== data.field.key }" />
                  <feather-icon icon="ChevronDownIcon" :class="{ 'text-primary': sortDesc && sortBy === data.field.key, 'text-secondar': !sortDesc || sortBy !== data.field.key }" />
                </template>
                <flat-pickr class="form-control bg-white" v-model="createdAt" @on-change="searchTime"  :config="{mode: 'range',dateFormat: 'Y-m-d H:i:ss' }" />
              </div>
        </template>
        </AdvanceTable>
    </section>
  </div>
</template>

<script>
import AdvanceTable from "@core/components/advanceTable/advanceTable.vue";
import JsonViewer from 'vue-json-viewer'
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { readFile } from 'vxe-table';
export default {
  components: {
    AdvanceTable,ToastificationContent,
    JsonViewer,flatPickr
  },
  data() {
    return {
      window: {
        width: 0,
        height: 0
      },
      columns: [
        { key: "createdAt", modelName: "createdAt", label: "Create At", width: "160", filtertype: "datetime", sortable: true },
        { key: "tkID", modelName: "tkID", label: "tkID", width: "200", filtertype: "input", sortable: true },
        { key: "customerOrderNo", modelName: "customerOrderNo", label: "Customer Order Number", width: "200", filtertype: "input", sortable: true },
        { key: "refTkID", modelName: "refTkID", label: "SF Tracking Number", filtertype: "input", sortable: true },
        { key: "pkSN", modelName: "pkSN", label: "Parcel Sequence", sortable: true },
        { key: "inboundStatus", modelName: "inboundStatus", label: "Inbound(Y/N)", filtertype: "select",options: { 1: "Yes", 0: "No" }, sortable: true },
        { key: "status", modelName: "status", label: "Status", filtertype: "select", options: { pending: "pending", fail: "fail", success:'success' }, sortable: true },
        { key: "errorMsg", modelName: "errorMsg", label: "SF Error Message", sortable: true },
        { key: "action", modelName: "action", label: "",  width: 50, align: "center" }
      ],
      createdAt:'',
      sortBy: "",
      sortDesc: true,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
  },
  methods: {
    resendToSF(item){
      console.info(item,'item')
      let ids = [item.tkID]
      this.$http.post('/sfService/createOrder', ids).then(res=>{
        if (res.data.code == 200) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$refs.advanceTable.loadList()
        }else{
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    searchTime(val){
        let date1  = val[0]?this.formatDate(val[0])+ ' 00:00:00':''
        let date2  = val[1]?this.formatDate(val[1])+ ' 23:59:59':''
        if (date1 != NaN &&date2 != NaN) {
            this.$refs.advanceTable.loadList({'createdAtFrom':date1,'createdAtTo':date2});
        }
    },
    clearData(){
        this.createdAt=''
    },
    formatDate(date) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;

      return [year, month, day].join('-');
    }
  }
};
</script>

<style scoped></style>
